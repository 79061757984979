import { PageType } from "@product/scmp-sdk";

export const pathname = "/spotlight";

export const page = {
  route(query: QueryParameters<{ page: "spotlight" | "series" }>) {
    return {
      pathname,
      query: { ...query, pageType: PageType.Series },
    };
  },
};
