import { PageType } from "@product/scmp-sdk";

import type { AppBarVariant } from "scmp-app/components/app-bar/types";

const getArticlePageType = (appBarVariant?: AppBarVariant) => {
  if (appBarVariant === "posties/generic") {
    return PageType.PostiesArticle;
  }
  return PageType.Article;
};

export const pathname = "/article";

export const page = {
  route(
    query: QueryParameters<{
      appBarVariant?: AppBarVariant;
      commentID?: string;
      entityId: string;
      entityUuid: string;
      share?: string;
    }>,
  ) {
    return {
      pathname,
      query: { ...query, pageType: getArticlePageType(query.appBarVariant) },
    };
  },
};
