import { PageType } from "@product/scmp-sdk";

export const pathname = "/story-style";
export const staticPathname = "/story/style";

export const page = {
  route(
    query: QueryParameters<{
      subSection: string;
    }>,
  ) {
    return {
      pathname,
      query: { ...query, pageType: PageType.StoryStyle },
    };
  },
};
